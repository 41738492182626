import { useEffect } from "react";
import { useRouterUtils } from "@/app/routerUtils";
export default function Custom404() {
  const {
    goHome
  } = useRouterUtils();
  useEffect(() => {
    setTimeout(() => {
      goHome();
    }, 2000);
  }, []);
  return <h1 data-sentry-component="Custom404" data-sentry-source-file="404.page.tsx">
      This page has moved, redirecting you to the <a href={"/"}>homepage</a> in
      a moment{" "}
    </h1>;
}